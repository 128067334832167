body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.fixed-store-info {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  background-color: #eee; 
}

.item-information {
  margin-top: 200px;
  flex-grow: 1;
  padding: 10x;
  overflow-y: scroll;
  
}

.item-detail-information {
  margin-top: 100px;
  flex-grow: 1;
  padding: 10x;
  overflow-y: scroll;
  
}

.view-item-container {
  position: relative;
  padding-bottom: 200px; 
}

a {
  color: inherit; /* or "initial" */
  text-decoration: none !important;
}

/* Custom link styles */
.custom-link {
  color: #000; /* Blue link color */
  text-decoration: none; /* Remove underline */
}

/* Apply the styles when the link is hovered */
.custom-link:hover {
  color: #0056b3; /* Darker blue on hover */
  text-decoration: none; /* Remove underline on hover */
}